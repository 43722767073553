import { graphql } from 'gatsby';
import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import ImageOpt from '../ImageOpt.js';

const Banner = ({ postData }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FBF7F6',
      border: '0',
      borderRadius: '0',
      zIndex: 100,
      padding: '50px',
    },
  };

  const customStylesVideo = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FBF7F6',
      border: '0',
      borderRadius: '0',
      padding: '0',
    },
  };

  const [modalIsOpenVideo, setIsOpenVideo] = React.useState(false);

  function openModalVideo() {
    document.body.classList.add('video-modal-open');
    setIsOpenVideo(true);
  }

  function closeModalVideo() {
    document.body.classList.remove('video-modal-open');
    setIsOpenVideo(false);
  }

  return (
    <>
      <section className="banner h-screen relative">
        <div className="banner-bg-img h-full bg-cover">
          {postData.video ? (
            <video
              preload="auto"
              poster={postData.image.mediaItemUrl}
              playsInline="playsinline"
              autoPlay="autoplay"
              muted="muted"
              loop="loop"
              style={{ width: `100%`, objectFit: `cover`, height: `100%` }}
            >
              <source src={postData.video} type="video/mp4" />
            </video>
          ) : (
            <ImageOpt
              imgData={postData.image.mediaItemUrl}
              imgAlt="banner"
              imgLoad="eager"
              width={1440}
              height={856}
              imgClass="banner-bg-img h-full w-full object-cover bg-cover"
            />
          )}
        </div>
        <div className="banner-btm-text relative z-[5]">
          <div className="banner-info text-center lg:pb-120 pb-50  fade-ani absolute bottom-0 w-full">
            {postData.video && (
              <div className="w-full">
                <button
                  onClick={() => {
                    openModalVideo();
                  }}
                  className="inline-block play-icon"
                >
                  <img src="../../images/play-icon.svg" alt="" />
                </button>
              </div>
            )}
            <div className="flex justify-center lg:space-x-10 space-x-5">
              <h6 className="font-body tracking-018em text-14 text-white relative uppercase">
                Accommodation
              </h6>
            </div>
            <div className="banner-title mt-30">
              <h1 className="text-white tracking-004em up">{postData.title}</h1>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpenVideo}
        onRequestClose={closeModalVideo}
        style={customStylesVideo}
      >
        <div className="video-modal-popup">
          <button onClick={closeModalVideo} className="pr-30 pt-30">
            <img src="../../images/modal-close.svg" alt="" />
          </button>
          <div className="modal-video">
            <ReactPlayer
              className="modal-banner-video"
              loop={true}
              playing={true}
              styleVideo={customStyles}
              controls={true}
              muted={true}
              url={postData.video}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Banner;
export const AccommodationBannerFragment = graphql`
  fragment AccommodationBannerFragment on WpAccommodation_Roomscontent_AccommodationsContent_Banner {
    # content
    id
    backgroundVideo
    fieldGroupName
    extraClass
  }
`;
