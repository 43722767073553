import { graphql } from 'gatsby';
import React from 'react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageOpt from '../ImageOpt.js';

const FullImageSlider = ({ module }) => {
  return (
    <>
      <section className="full-img-slider lg:py-60 py-30">
        <div className="container-fluid pl-0 mPaddingRemove">
          <div className="title-brown title-line title-right">
            <h3>{module.heading}</h3>
          </div>
        </div>
        <div className="container-fluid">
          <div className="full-slider bg-white lg:mt-60 mt-30">
            <Swiper
              speed={1000}
              effect={'fade'}
              loop={true}
              pagination={{
                type: 'fraction',
                formatFractionCurrent: function (number) {
                  return ('0' + number).slice(-2);
                },
                formatFractionTotal: function (number) {
                  return ('0' + number).slice(-2);
                },
                renderFraction: function (currentClass, totalClass) {
                  return (
                    '<span class="' +
                    currentClass +
                    '"></span>' +
                    '<div class="counter-lne">/</div>  ' +
                    '<span class="' +
                    totalClass +
                    '"></span>'
                  );
                },
              }}
              navigation={true}
              modules={[Navigation, Pagination, EffectFade]}
            >
              {module.fullImageSliders.map((item, i) => {
                return (
                  <div key={i}>
                    <SwiperSlide>
                      <div className="img">
                        {item.image && (
                          <ImageOpt
                            imgData={item.image.mediaItemUrl}
                            imgAlt={item.image.title}
                            imgLoad="lazy"
                            width={1290}
                            height={630}
                            imgClass="object-cover h-[630px] mdscreen:h-[65vw] w-full"
                          />
                        )}
                      </div>
                      <span className="mt-50 inline-block text-18 text-brown pl-150 full-img-slider-text">
                        {item.title}
                      </span>
                    </SwiperSlide>
                  </div>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullImageSlider;
export const AccommodationsFullImageSliderFragment = graphql`
  fragment AccommodationsFullImageSliderFragment on WpAccommodation_Roomscontent_AccommodationsContent_FullImageSlider {
    # content
    id
    heading
    extraClass
    fieldGroupName
    fullImageSliders {
      title
      image {
        altText
        uri
        title
        mediaItemUrl
      }
    }
  }
`;
