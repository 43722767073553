import React from "react";
import { graphql } from "gatsby";
import AccommodationListingSlider from "../Modules/AccommodationListingSlider";

const AccommodationSlider = ({ module, i,postData }) => {
  return (
    <>
      <AccommodationListingSlider postData={postData} />
    </>
  );
};

export default AccommodationSlider;
export const AccommodationSliderSingleFragment = graphql`
  fragment AccommodationSliderSingleFragment on  WpAccommodation_Roomscontent_AccommodationsContent_AccommodationSlider {
    # content
    heading
  }
`;
