import { Link, graphql } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import parse from 'html-react-parser';
import React, { useEffect } from 'react';
import ImageOpt from '../ImageOpt.js';
import { BrandSettingsData } from './BrandSettingsData';

const ExploreCard = () => {
  const brandData = BrandSettingsData();
  useEffect(() => {
    document.body.classList.remove('menu-open');
    gsap.registerPlugin(ScrollTrigger);
    var animation_y = 1;
    gsap.utils.toArray('.explore-fade-ani').forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: 'play none none none',
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 90 },
            { duration: animation_y, autoAlpha: 1, y: 0 }
          );
        },
      });
      animation_y = animation_y + 0.5;
    });
  }, []);
  return (
    <>
      <div className="explore-card bg-lightgray lg:py-60 py-30 ">
        <div className="container-fluid ">
          <div className="title-black text-center">
            <h3>{brandData.cardMainHeading}</h3>
          </div>

          <div className="explore-card-grid lg:mx-minus-30 md:mx-minus-20">
            <div className="flex flex-wrap">
              {brandData.globalCard.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`xl:w-4/12 explore-fade-ani md:w-4/12 xl:px-30 md:px-20 w-full ${
                      i === brandData.globalCard.length - 1 ? 'hidden' : ''
                    }`}
                  >
                    <div className="card-bx lg:mt-50 mt-30">
                      <div className="img">
                        {item.image && (
                          <Link to={item.button.url}>
                            <ImageOpt
                              imgData={item.image.mediaItemUrl}
                              imgAlt="Location"
                              imgLoad="lazy"
                              width={390}
                              height={456}
                              imgClass="block object-cover h-full w-full"
                            />
                          </Link>
                        )}
                      </div>

                      <div className="content   pt-30">
                        <h5 className="lg:text-30 text-24 text-black2">
                          <Link to={item.button.url}> {item.heading}</Link>
                        </h5>
                        <p className="text-12">{parse(item.description)}</p>
                        <Link to={item.button.url} className="link-btn mt-15">
                          <span></span>
                          {item.button.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExploreCard;
export const AccommodationExploreCardFragment = graphql`
  fragment AccommodationExploreCardFragment on WpAccommodation_Roomscontent_AccommodationsContent_ExploreCard {
    # content
    id
    extraClass
  }
`;
