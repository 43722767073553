import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import Modal from 'react-modal';
import ImageOpt from '../ImageOpt.js';

const ImageWithTabContent = ({ module }) => {
  const [tabOption, setTabOption] = useState(module.tabs[0].title);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FBF7F6',
      border: '0',
      borderRadius: '0',
      padding: '50px',
    },
  };

  return (
    <>
      <section className="zigzag portrait lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap lg:space-y-0 space-y-10">
            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                <div className="title-black">
                  <h2>{module.heading}</h2>
                </div>
                <div className="content">
                  <ul className="tabs flex space-x-5 mb-30">
                    {module.tabs.map((item, i) => {
                      return (
                        <li
                          onClick={() => {
                            setTabOption(item.title);
                          }}
                          className={`tab-link cursor-pointer text-brown capitalize font-400 text-18 ${
                            tabOption === item.title ? 'tab-current' : ''
                          }`}
                          data-tab={item.title}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>

                  {module.tabs.map((item, i) => {
                    return (
                      <div className="tabbox">
                        <div
                          id={item.title}
                          className={`tab-content tab-current global-list ${
                            tabOption === item.title ? 'tab-current' : 'hidden'
                          }`}
                        >
                          {parse(item.description)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="btn-custom lg:mt-40 mt-20">
                  {module.button && (
                    <Link
                      to={module.button.url}
                      target={module.button.target}
                      className="button button-tan"
                    >
                      {module.button.title}
                    </Link>
                  )}
                  <button className="text-link" onClick={openModal}>
                    view full rates
                  </button>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              {module.image && (
                <ImageOpt
                  imgData={module?.image.mediaItemUrl}
                  imgAlt={module.image.altText}
                  imgLoad="lazy"
                  width={645}
                  height={537}
                  imgClass="object-cover w-full h-full"
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <button className="absolute right-60" onClick={closeModal}>
          <img src="../../images/modal-close.svg" loading="lazy" alt="" />
        </button>
        <div className="rates-modal">
          <div className="modal-title lg:pt-50 pt-20 lg:pl-30 pl-0 border-b-1 border-tan border-opacity-40 pb-30">
            <span className="text-tan tracking-004em text-14 uppercase font-body font-400">
              {module.heading}
            </span>
            {module.years && <h2 className="pt-15">{module.years}</h2>}
          </div>
          <div className="modal-content">
            <div className="lg:grid lg:grid-cols-4 lg:gap-x-20 grid grid-cols-2 md:gap-0 lg:px-30 px-0 modal-m-border">
              {module.viewRates &&
                module.viewRates.map((item, i) => {
                  return (
                    <div className="modal-col lg:pt-50 pt-20">
                      <h6 className="text-14 text-dark text-opacity-70 font-400 pb-20">
                        {item.date}
                      </h6>
                      <h4 className="text-brown tracking-003em capitalize font-400">
                        {item.rate}
                      </h4>
                      <span className="text-10 tracking-003em lowercase font-300 text-brown">
                        {item.tagline}
                      </span>
                    </div>
                  );
                })}
            </div>
            {module.popupbutton && (
              <div className="btn-custom lg:pl-30 pl-0 pt-30">
                <a className="button button-tan" href={module.popupbutton.url}>
                  {module.popupbutton.title}
                </a>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageWithTabContent;
export const AccommodationsImageWithTabContentFragment = graphql`
  fragment AccommodationsImageWithTabContentFragment on WpAccommodation_Roomscontent_AccommodationsContent_ImageWithTabContent {
    # content
    id
    tabs {
      description
      title
    }
    button {
      target
      title
      url
    }
    buttonStyle
    description
    extraClass
    heading
    years
    popupbutton {
      url
      title
      target
    }
    viewRates {
      date
      rate
      tagline
    }
    image {
      mediaItemUrl
      altText
      uri
      title
    }
    imagePosition
    imageSize
    sectionStyle
    imageBorderStyle
  }
`;
