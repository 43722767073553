import { graphql } from 'gatsby';
import React from 'react';
import AccommodationFutures from './AccommodationFutures';
import AccommodationSlider from './AccommodationSlider';
import Accordionlist from './Accordionlist';
import ExploreCard from './ExploreCard';
import FullImageSlider from './FullImageSlider';
import ImageContent from './ImageContent';
import ImageWithTabContent from './ImageWithTabContent';

const AccommodationsModules = (props) => {
  const modules = props.modules.accommodationsContent;
  const components = {
    ImageContent,
    FullImageSlider,
    ImageWithTabContent,
    Accordionlist,
    AccommodationSlider,
    ExploreCard,
  };

  return (
    <>
      <AccommodationFutures data={props.modules.accommodationFutures} />
      <>
        {modules
          ? modules.map((module, i) => {
              const moduleName = module.__typename.replace(
                'WpAccommodation_Roomscontent_AccommodationsContent_',
                ''
              );
              return (
                components[moduleName] &&
                React.createElement(components[moduleName], {
                  key: i,
                  module,
                  i,
                  postData: props.postData,
                })
              );
            })
          : null}
      </>
    </>
  );
};

export default AccommodationsModules;
export const AccommodationsModulesFragment = graphql`
  fragment AccommodationsModulesFragment on WpAccommodation {
    roomsContent {
      accommodationFutures {
        title
        fieldGroupName
        icon {
          altText
          uri
          title
          mediaItemUrl
        }
      }
      accommodationsContent {
        __typename
        ...AccommodationsImageContentFragment
        ...AccommodationsFullImageSliderFragment
        ...AccommodationsImageWithTabContentFragment
        ...AccordionImageWithTabContentFragment
        ...AccommodationSliderSingleFragment
        ...AccommodationExploreCardFragment
        ...AccommodationBannerFragment
      }
    }
  }
`;
