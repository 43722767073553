import React from 'react';
const AccommodationFutures = ({ data, i }) => {
  return (
    <>
      {data && (
        <section className="icon-list lg:py-60 py-30 lg:mt-60 mt-30">
          <div className="container m-auto">
            <div className="lg:w-10/12 w-full m-auto">
              <span className="icon-list__header">At a glance</span>
              <div className="grid grid-cols-2 md:flex md:flex-row md:justify-around">
                {data &&
                  data.map((item, i) => {
                    return (
                      <div key={i} className="icon-content text-center">
                        <img
                          src={item.icon.mediaItemUrl}
                          alt={item.title}
                          loading="lazy"
                          className="m-auto"
                        />
                        <span className="mt-15 max-w-150 px-10 inline-block text-dark text-12 text-opacity-70 font-400">
                          {item.title}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AccommodationFutures;
