import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const ImageContent = ({ module, i, postData }) => {
  return (
    <>
      <section
        key={i}
        className={`zigzag lg:py-60 py-30 ${module.extraClass} ${
          i === 0 ? 'mt-30 lg:mt-60' : ''
        } ${module.imageSize}`}
      >
        <div className={`container-fluid `}>
          <div className="flex flex-wrap items-center lg:space-y-0 space-y-10">
            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === 'right' ? 'lg:order-2' : ''
              }`}
            >
              <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                <span>Accommodation</span>
                <div className="title-black">
                  <h2>{module.heading}</h2>
                </div>
                <div className="content global-list">
                  {parse(module.description)}
                </div>
                <div className="btn-custom lg:mt-40 mt-20">
                  {module.button && (
                    <Link
                      to={module.button.url}
                      target={module.button.target}
                      className="button button-tan"
                    >
                      {module.button.title}
                    </Link>
                  )}
                  {module.secondaryCta && (
                    <Link
                      to={module.secondaryCta.url}
                      state={{ room: postData.title }}
                      className="text-link"
                    >
                      {module.secondaryCta.title}
                    </Link>
                  )}
                </div>
                {module.extraClass === 'add-disclaimer' && (
                  <div className="content">
                    <p className="disclaimer-text text-[12px] opacity-80">
                      *Dwyka Tented Lodge is closed for annual maintenance until
                      the 7th of October.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              {module.image && (
                <ImageOpt
                  imgData={module?.image.mediaItemUrl}
                  imgAlt={module.image.altText}
                  imgLoad="lazy"
                  width={645}
                  height={648}
                  imgClass="object-cover w-full h-full"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageContent;
export const AccommodationsImageContentFragment = graphql`
  fragment AccommodationsImageContentFragment on WpAccommodation_Roomscontent_AccommodationsContent_ImageContent {
    # content
    id
    sectionStyle
    imageSize
    imagePosition
    imageBorderStyle
    secondaryCta {
      url
      title
      target
    }
    button {
      url
      title
      target
    }
    image {
      altText
      uri
      title
      mediaItemUrl
    }
    heading
    description
    buttonStyle
    extraClass
    fieldGroupName
  }
`;
