import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import AccommodationFutures from '../components/ModuleAccommodations/AccommodationFutures';
import AccommodationsModules from '../components/ModuleAccommodations/AccommodationsModules';
import Banner from '../components/ModuleAccommodations/Banner';
import Seo from '../components/Seo/Seo';

const PageTemplate = (props) => {
  const post = props.data.post;
  const postData = {
    image: props.data.post.featuredImage.node,
    title: props.data.post.title,
    id: props.data.post.id,
    video: post.roomsContent?.accommodationsContent[7]?.backgroundVideo,
  };
  useEffect(() => {
    document.body.classList.remove('mega-menu-open');
    document.body.classList.remove('booking-open');
    document.body.classList.remove('form-enquiry-open');
    document.body.classList.remove('menu-open');
  });
  return (
    <Layout>
      <Seo seo={post.seo}></Seo>
      <Banner postData={postData} />
      <AccommodationFutures />
      <AccommodationsModules postData={postData} modules={post.roomsContent} />
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query PostById($id: String!) {
    post: wpAccommodation(id: { eq: $id }) {
      title
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...AccommodationsModulesFragment
    }
  }
`;
