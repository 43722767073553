import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const Accordionlist = ({ module, i }) => {
  return (
    <>
      <section className="faq lg:py-60 py-30">
        <div className="container m-auto">
          <div className="text-center">
            <span>Frequently asked questions</span>
          </div>
          <div className="accordian-section lg:pt-50 pt-30">
            <Accordion allowZeroExpanded={true}>
              {module.accordionlist.map((item, index) => (
                <AccordionItem key={index}>
                  <AccordionItemHeading className="accordion">
                    <AccordionItemButton>{item.title}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="fade-ani">
                    <p>{parse(item.details)}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accordionlist;
export const AccordionImageWithTabContentFragment = graphql`
  fragment AccordionImageWithTabContentFragment on WpAccommodation_Roomscontent_AccommodationsContent_Accordionlist {
    # content
    accordionlist {
      title
      details
    }
  }
`;
